<template>
  <div>
    <v-app-bar app flat>
      <v-container class="py-0 fill-height">
        <img :src="Logo" width="110" class="mr-2">
        <v-toolbar-title>
          {{ $t("appTitle") }}
        </v-toolbar-title>
      </v-container>
    </v-app-bar>
    <v-main class="mt-2">
      <v-container>
        <fade-transition :duration="100" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view />
        </fade-transition>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { FadeTransition } from "vue2-transitions"
import Logo from "@/assets/images/logo.svg"

export default {
  components: {
    FadeTransition,
  },
  data: () => ({
    Logo,
  }),
}
</script>

<style lang="scss"></style>
