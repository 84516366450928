<template>
  <div class="text-center">
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn icon dark v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <slot />
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({}),
  methods: {},
}
</script>

<style></style>
