<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-card rounded="lg">
          <v-list color="transparent">
            <v-list-item-group>
              <v-list-item v-for="route in routes" :key="route.path" :to="route.path">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ route.displayName }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card padrounded="lg">
          <router-view />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routes: [
        { path: "/settings/order_sources", displayName: "Order sources" },
        { path: "/settings/inputs", displayName: "Inputs" },
        { path: "/settings/sftp_hosts", displayName: "Hosts" },
      ],
    }
  },
}
</script>

<style></style>
