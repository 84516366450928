import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"
import store from "../store"

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.public)) {
    next()
  } else if (store.getters.isAuthenticated) {
    next()
  } else {
    next("/login")
  }
})

export default router
