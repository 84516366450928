import Vue from "vue"

import i18n from "@/plugins/vue-i18n"
import vuetify from "@/plugins/vuetify"
import _flowy from "@/plugins/flowy"
import _debounce from "@/plugins/vue-debounce"

import router from "routes/"
import store from "store/"
import App from "../App.vue"

import axios from "axios"

import "simple-syntax-highlighter/dist/sshpre.css"

axios.defaults.withCredentials = true
axios.defaults.baseURL = "/internal_api/"

axios.interceptors.request.use(function (config) {
  const token = store.getters.stateToken

  if (token) {
    config.headers.common.Authorization = "Bearer " + token
  } else {
    config.headers.common.Authorization = null
  }

  return config
})

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      store.dispatch("logOut")
      router.push("/login")
    }

    return Promise.reject(error)
  }
})

// Components
Vue.component("SnackBar", () => import('../components/SnackBar.vue'))

// Filters
Vue.filter("prettifyDate", function (value) {
  if (value == undefined)
    return "-"

  return new Date(value).toLocaleString("sv-SE")
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")
