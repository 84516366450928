import axios from "axios"

const state = {
  token: null,
}

const getters = {
  isAuthenticated: (state) => !!state.token,
  stateToken: (state) => state.token,
}

const actions = {
  async logIn({ commit }, user) {
    let response = await axios.post("/login", user)
    let token = response.headers.authorization.match(/Bearer (.*)/)[1]
    await commit("setToken", token)
  },

  async logOut({ commit }) {
    await axios.delete("/logout")
    commit("logout")
  },
}

const mutations = {
  setToken(state, token) {
    state.token = token
  },
  logout(state) {
    state.token = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
