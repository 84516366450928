import Vue from "vue"
import VueI18n from "vue-i18n"

import messages from "locales/en"
import dateFormats from "locales/date-formats"
import numberFormats from "locales/number-formats"

Vue.use(VueI18n)

const opts = {
  locale: "en",
  fallbackLocale: "en",
  messages,
  dateFormats,
  numberFormats,
}

export default new VueI18n(opts)
