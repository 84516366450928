<template>
  <div class="flex-shrink-1 flex-grow-0">
    <v-card class="pa-3" outlined tile>
      <div class="text-h9">
        {{ title }}
      </div>
      <div class="text">
        {{ text }}
      </div>
      <p v-if="description">
        {{ description }}
      </p>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "FlowChartNode",
  props: ["node", "title", "description", "text"],
  data() {
    return {}
  },
}
</script>

<style>
</style>
