import AppLayout from "../views/Layout/AppLayout.vue"
import PublicLayout from "../views/Layout/PublicLayout.vue"
import SettingsLayout from "../views/Layout/SettingsLayout.vue"

const routes = [
  {
    path: "/",
    redirect: "orders",
    component: AppLayout,
    children: [
      {
        path: "/settings",
        name: "settings",
        redirect: "settings/order_sources",
        component: SettingsLayout,
        children: [
          { path: "order_sources", component: () => import("../views/Pages/OrderSources.vue") },
          { path: "order_sources/new", component: () => import("../views/settings/NewOrderSource.vue") },
          { path: "order_sources/:id", props: true, component: () => import("../views/settings/EditOrderSource.vue") },
          { path: "sftp_hosts", component: () => import("../views/Pages/SftpHosts.vue") },
          { path: "sftp_hosts/new", component: () => import("../views/settings/NewSftpHost.vue") },
          { path: "sftp_hosts/:id", props: true, component: () => import("../views/settings/EditSftpHost.vue") },
          { path: "inputs", component: () => import("../views/Pages/Inputs.vue") },
          { path: "inputs/:id", props: true, component: () => import("../views/Pages/Input.vue") }
        ]
      },
      {
        path: "/orders",
        name: "orders",
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Pages/Orders.vue"),
      },
      {
        path: "/orders/:id",
        name: "order",
        props: true,
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Pages/Order.vue"),
      },
      {
        path: "/data_requests",
        name: "dataRequests",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "../views/Pages/DataRequests.vue"
          ),
      },
      {
        path: "/data_requests/:id",
        name: "dataRequest",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "../views/Pages/DataRequest.vue"
          ),
      },
    ],
  },
  {
    path: "/admin",
    beforeEnter() {
      location.href = "/admin"
    }
  },
  {
    path: "/sidekiq",
    beforeEnter() {
      location.href = "/sidekiq"
    }
  },
  {
    path: "/public",
    redirect: "login",
    component: PublicLayout,
    meta: { public: true },
    children: [
      {
        path: "/login",
        name: "login",
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Login.vue"),
      },
    ],
  },
]

export default routes
