<template>
  <v-app id="proximity">
    <router-view />
    <snack-bar />
  </v-app>
</template>

<script>
export default {}
</script>
