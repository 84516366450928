export default {
  sv: {
    currency: {
      style: "currency",
      currency: "SEK",
      minimumFractionDigits: 0,
    },
    currencyFraction: {
      style: "currency",
      currency: "SEK",
      minimumFractionDigits: 2,
    },
  },
}
