<template>
  <div>
    <v-app-bar app flat>
      <v-container class="py-0 fill-height">
        <img :src="Logo" width="110" class="mr-2">
        <v-toolbar-title>
          {{ $t("appTitle") }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          v-for="link in links"
          :key="link.name"
          :to="link.name"
          text
          class="mr-1"
        >
          {{ link.text }}
        </v-btn>

        <v-spacer />
        <overflow-menu>
          <v-list-item link to="/admin">
            <v-list-item-title>
              Admin
            </v-list-item-title>
          </v-list-item>
          <v-list-item link to="/sidekiq">
            <v-list-item-title>
              Sidekiq
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-title @click="logout">
              Logout
            </v-list-item-title>
          </v-list-item>
        </overflow-menu>
      </v-container>
    </v-app-bar>
    <v-main class="mt-2">
      <v-container>
        <fade-transition :duration="100" origin="center top" mode="out-in">
          <!-- your content here -->
          <keep-alive include="Orders">
            <router-view />
          </keep-alive>
        </fade-transition>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { FadeTransition } from "vue2-transitions"
import OverflowMenu from "../../components/OverflowMenu.vue"
import Logo from "@/assets/images/logo.svg"

export default {
  components: {
    FadeTransition,
    OverflowMenu,
  },
  data: () => ({
    Logo,
    links: [
      {
        name: "/orders",
        text: "Orders",
      },
      {
        name: "/data_requests",
        text: "In data",
      },
      {
        name: "/settings",
        text: "Settings",
      },
    ],
  }),
  methods: {
    async logout() {
      await this.$store.dispatch("logOut")
      this.$router.push("/login")
    },
  },
}
</script>

<style scoped lang="scss">
</style>
